<template>
	<div class="boxx">
		<div class="bar" id="pie" ref="pie"></div>
	</div>
</template>

<script>
	import Highcharts from 'highcharts/highstock';
	import Highcharts3D from 'highcharts/highcharts-3d';
	Highcharts3D(Highcharts);
	export default {
		data() {
			return {
				data:[
					{name:'5000万以下',y:3093.66},
					{name:'5亿以上',y:2195.8},
					{name:'1亿~5亿',y:1304.63},
					{name:'5000万~1亿',y:974.71},
					{name:'其他',y:214.42},
				]
			}
		},
		created() {
		},
		mounted() {
			this.chart(this.data)
		},
		methods:{
			chart(data){
				var _this=this
				var chart = Highcharts.chart('pie', {
						title:{
							text:"产值规模",
							align:"left",
							style:{
								color:"#333",
								fontSize:"18px",
							  fontWeight:"700"
							}
						},
            subtitle:{
              text:"单位:万千瓦/时",
              align:"left",
              style:{
              	fontSize:"14px"
              }
            },
						credits: {
                enabled:false
						},
						chart: {
								type: 'pie',
								options3d: {
									enabled: true,
									alpha: 45,
									beta: 0,
								},
								backgroundColor:"rgba(0,0,0,0)"
						},
						tooltip: {
								pointFormat: '{series.name}: <b>{point.y}</b>'
						},
						legend:{
						},
						plotOptions: {
							pie: {
								allowPointSelect: true,
								cursor: 'pointer',
								depth: 50,
								size:"60%",
								dataLabels: {
									enabled: true,
									useHTML:true,
									distance:20,
									connectorPadding:0,
									crop:false,
									formatter:function (val){
										var str2 =this.point.name+'<br>'+this.y+"占比:"+this.percentage.toFixed(0)+'%'
										return str2
									}
								},
								showInLegend: true,
							}
						},
						series: [{
								type: 'pie',
								name: '产值规模',
								colors:["#4df29b","#ffd20d","#18b6da","#5f7bd4","#068d51"],
								data:data,
								events: {
									click: function (event) {
										console.log(event.point.options)
									}
								}
						}]
				});
			},
		}
	}
</script>

<style lang="less" scoped="scoped">
	.boxx{
		width: 100%;
		height: 100%;
		.bar{
			width: 100%;
			height: 100%;
      background-color: #FFFFFF;
      border-radius: 4px;
		}
	}
</style>
