<template>
	<div id="box">
		<div class="box">
			<div class="top">
				<div class="topleft">
          <h1>信用分类</h1>
            <div class="topleftbox">
              <div class="wai" @click="active(2)">
                <div class="nei">
                  <img src="../../assets/superviseimg/u748.png" />
                  <p>{{creditGroup.lianghao}}家</p>
                  <p>良好[800,850]</p>
                </div>
              </div>
              <div class="wai"  @click="active(3)">
                <div class="nei">
                  <img src="../../assets/superviseimg/u766.svg" />
                  <p>{{creditGroup.zhongdeng}}家</p>
                  <p>中等[750,800]</p>
                </div>
              </div>
            </div>
            <div class="bomleftbox">
              <div class="wai"  @click="active(5)">
                <div class="nei">
                  <img src="../../assets/superviseimg/u752.svg" />
                  <p>{{creditGroup.cha}}家</p>
                  <p>差[0,700]</p>
                </div>
              </div>
              <div class="wai"  @click="active(4)">
                <div class="nei">
                  <img src="../../assets/superviseimg/u772.svg" />
                  <p>{{creditGroup.jiaocha}}家</p>
                  <p>较差[700,750]</p>
                </div>
              </div>
            </div>
            <div class="wai" @click="active(1)">
              <div class="nei">
                <img src="../../assets/superviseimg/u759.svg" />
                <p>{{creditGroup.youxiu}}家</p>
                <p>优秀[850,1000]</p>
              </div>
            </div>
				</div>
				<div class="topright">
          <h1>用能情况</h1>
          <div class="topbom">
          	<div class="topitem">
          		<p>规上电力消费总量</p>
          		<p><span>7229.12</span><span>万千瓦/时</span></p>
          	</div>
          	<div class="topitem">
          		<p>印刷和记录媒介复制业</p>
          		<p><span>1695.18</span><span>万千瓦/时</span></p>
          	</div>
          	<div class="topitem">
          		<p>规下电力消费总量</p>
          		<p><span>558.68</span><span>万千瓦/时</span></p>
          	</div>
          	<div class="topitem">
          		<p>造纸和纸质品业</p>
          		<p><span>373.57</span><span>万千瓦/时</span></p>
          	</div>
          </div>
				</div>
			</div>
			<div class="bom">
				<supertabel :queryCreditGroup="checkggroup"></supertabel>
			</div>
      <div class="bom2">
        <div class="bom2left">
          <chanzhiguimo></chanzhiguimo>
        </div>
        <div class="bom2right">
          <zongheyongdian></zongheyongdian>
        </div>
      </div>
		</div>
	</div>
</template>

<script>
	import supertabel from "@/components/supervise/supertabel"
  import chanzhiguimo from "@/components/supervise/chanzhiguimo"
  import zongheyongdian from "@/components/supervise/zongheyongdian"
	import {creditGroup} from "@/api/supervise/supervise"
	export default {
	  data (){
	    return{
        creditGroup:{
          youxiu:0,
          lianghao:0,
      zhongdeng:0,
      cha:0,
      jiaocha:0
        },
        checkggroup:0
      }
    },
		components:{
			supertabel,
      chanzhiguimo,
      zongheyongdian
		},
    mounted() {
	    this.getCreditGroup()
    },
    methods:{
      active(val){
        this.checkggroup=val
      }
      ,
	    getCreditGroup(){
        let _this = this
		  creditGroup().then(res=> {
          console.log(res)
          if (res.data.data) {
            res.data.data.forEach(item => {
              if(item.creditRatings==='优秀'){_this.creditGroup.youxiu=item.gradeCount}
              if(item.creditRatings==='良好'){_this.creditGroup.lianghao=item.gradeCount}
              if(item.creditRatings==='中等'){_this.creditGroup.zhongdeng=item.gradeCount}
              if(item.creditRatings==='较差'){_this.creditGroup.jiaocha=item.gradeCount}
              if(item.creditRatings==='差'){_this.creditGroup.cha=item.gradeCount}
            })
          }
        })
      }

    }
	}
</script>

<style scoped="scoped" lang="less">
	.box{
		width: 100%;
		min-height:600px ;
		background-color: #f2f3f5;
		border-radius: 4px;
	}
	.bom{
		width: 100%;
		min-height: 401px;
		border-radius: 4px;
		margin-top: 20px;
	}
  .bom2{
    width: 100%;
    height: 350px;
    display: flex;
    margin-top: 20px;
    align-items: center;
    justify-content: space-between;
    .bom2left,.bom2right{
      width: 49%;
      height: 100%;
    }
  }
	.top{
		width: 100%;
		display: flex;
		align-items: center;
		.topleft{
			width: 49%;
			height: 319px;
			background-color: #FFFFFF;
			border-radius: 4px;
			padding: 4px;
      position: relative;
		}
    .topleft>.wai{
      width: 120px;
      height: 100px;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      background-image: url(../../assets/superviseimg/u744.svg);
      background-size: 100% 100%;
      .nei{
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 100px;
        height: 85px;
        background-image: url(../../assets/superviseimg/u744.svg);
        background-size: 100% 100%;
        text-align: center;
        img{
          height: 40px;
        }
        p{
          font-size: 14px;
        }
        p:nth-child(2){
          color: #fe9601;
        }
      }
    }
    .topleftbox,.bomleftbox{
      width: 100%;
      height: 50%;
      display: flex;
      justify-content: center;
      .wai{
        width: 120px;
        height: 100px;
        background-image: url(../../assets/superviseimg/u744.svg);
        background-size: 100% 100%;
        position: relative;
        margin: 0 36px;
        .nei{
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
          width: 100px;
          height: 85px;
          background-image: url(../../assets/superviseimg/u744.svg);
          background-size: 100% 100%;
          text-align: center;
          img{
            height: 40px;
          }
          p{
            font-size: 14px;
          }
          p:nth-child(2){
            color: #fe9601;
          }
        }
      }
    }
		.topbom{
			width: 80%;
			height: 273px;
			margin: 0 auto;
			margin-top: 20px;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			.topitem{
				width: 198px;
				height: 77px;
				background-color: #FDF1EB;
				border-radius: 4px;
				padding: 16px;
			}
			.topitem>p:nth-child(2){
				margin-top: 10px;
				span:nth-child(1){
					font-size: 18px;
					font-weight: bold;
					color: #FF7532;
					margin-right: 5px;
				}
				span:nth-child(2){
					font-size: 14px;
					color: #999999;
				}
			}
		}
		.topright{
			width: 49%;
			height: 319px;
			margin-left: auto;
      background-color: #FFFFFF;
      border-radius: 4px;
      padding: 4px;
		}
	}
  .wai:hover{
    cursor: pointer;
  }
</style>
