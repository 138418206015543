<template>
  <div class="boxx">
    <div ref='bar' class="bara"></div>
  </div>
</template>

<script>
  export default {
    props: [],
    data() {
      return {
       /* data1: [57170826, 11119395, 37902225, 67316570, 62996039, 91315308, 67549110, 71577541, 67823223, 69459881,
          70072752, 75387704.68
        ],*/
        data1: [48630262.5883, 26720875.1798, 94463296.5713, 76791290.5643, 77419131.9928, 68062682.7493, 77056239.4411,
          78065204.5568, 80014617.5271, 70285940.7109, 102422760.0592, 77878038.162
        ],
        data2: [0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0
        ],
        data3: [0, 0, 0, 0,0, 0, 0,0, 0, 0, 0, 0]
      }
    },
    created() {},
    mounted() {
      var box = this.$refs.bar
      this.Chart(box, this.data1, this.data2, this.data3)
    },
    methods: {
      Chart(box, year20, year21, data3) {
        var myChart = this.$echarts.init(box);
        var option;
        option = {
          title: {
            text: '综合用电趋势',
            textStyle: {
              color: "#333333",
              fontSize: 18,
            }
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          legend: {
            data: ['去年同期', '今年', '同比增长率'],
          },
          grid: {
            left: '5%',
            right: '4%',
            bottom: '5%',
            containLabel: true,
          },
          xAxis: {
            type: 'category',
            data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
            axisLabel: {
              interval: 0,
              formatter: function(val) {
                var strs = val.split(''); //字符串数组
                var str = ''
                for (var i = 0, s; s = strs[i++];) { //遍历字符串数组
                  str += s;
                  if (!(i % 2)) str += '\n'; //按需要求余
                }
                return str
              },
            },
            axisLine: {
              show: true,
            },

          },
          dataZoom: [{
            type: 'slider',
            xAxisIndex: 0,
            bottom: 0,
            height: 12,
            showDetail: false,
            startValue: 0,
            endValue: 6,
            handleSize: '110%',
            handleStyle: {
              color: "#d3dee5",
            },
            textStyle: {
              color: "#fff"
            },
            borderColor: "#90979c"
          }, ],
          yAxis: [{
              type: 'value',
              boundaryGap: [0, 0.01],
              name: '单位:千瓦/时',
              axisLine: {
                show: true,
              },
              splitLine: {
                show: false
              },
            },
            {
              type: 'value',
              boundaryGap: [0, 0.01],
              position: "right",
              axisLine: {
                show: true,
              },
              splitLine: {
                show: false
              },
            }
          ],
          series: [{
              name: '去年同期',
              type: 'bar',
              // data: [2.96, 1.06, 2.16, 2.56, 2.66, 2.76,2.86, 2.96, 3.06, 3.16, 3.26, 3.36],
              data: year20,
              barWidth: 16,
              itemStyle: {
                color: "#4DF1F0",
              },
              label: {
                show: true,
                position: "top",
                color: "#4DF1F0",
              },
              barGap: "0%"
            },
            {
              name: '今年',
              type: 'bar',
              // data: [3.06, 2.56, 3.06, 3.46, 3.6, 3.56,3.26],
              data: year21,
              barWidth: 16,
              itemStyle: {
                color: "#3682E1"
              },
              label: {
                show: true,
                position: "top",
                offset: [0, -10],
              },
              barGap: "0%"
            },
            {
              name: '同比增长率',
              type: 'line',
              data: data3,
              yAxisIndex: 1,
              itemStyle: {
                color: "#b7e87e"
              },
              labelLine: {
                lineStyle: {
                  width: 2
                }
              },
              label: {
                show: true,
                position: "top",
                color: "#ff55ff",
                formatter: "{c}%"
              },
              barGap: "0%"
            }
          ]
        };
        option && myChart.setOption(option);
      }
    }
  }
</script>

<style lang="less" scoped="scoped">
  .boxx {
    width: 100%;
    height: 100%;

    .bara {
      width: 100%;
      height: 100%;
      background-color: #FFFFFF;
      border-radius: 4px;
    }
  }
</style>
