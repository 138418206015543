<template>
	<div class="boxx">
		<div class="top">
			<h1>企业信用平均值</h1>
       <el-input
          placeholder="请输入企业名称关键字"
          prefix-icon="el-icon-search"
          @change="select"
          clearable
          v-model="firmname">
        </el-input>
			<el-button-group>
			  <el-button :type="creditOrder=='desc'?'primary':''" @click="activetype('desc')">正排名</el-button>
			  <el-button :type="creditOrder=='asc'?'primary':''" @click="activetype('asc')">负排名</el-button>
			</el-button-group>
		</div>
		<div class="bom">
			<el-table
			    :data="tableData"
			    stripe
          v-loading="loading"
				:cell-style="{'text-align':'center'}"
				:header-cell-style="{'text-align':'center','font-size':'16px','font-weight':'bold','color':'#333'}"
				border
			    style="width: 100%">
			<!--    <el-table-column
			      prop="num"
			      label="排名"
				  width="80px"
			      >
			    </el-table-column>-->
			    <el-table-column
			      prop="companyName"
			      label="企业名称"
			      >
			    </el-table-column>
			    <el-table-column
			      prop="industryName"
			      label="企业类型">
			    </el-table-column>
				<el-table-column
				  prop="industrialArea"
				  label="所属片区">
				</el-table-column>
				<el-table-column
				  prop="projectName"
				  label="所属园区">
				</el-table-column>
				<el-table-column
				  prop="creditScore"
				  label="信用分值">
				</el-table-column>
        <el-table-column
          prop="creditRatings"
          label="信用等级">
        </el-table-column>
			</el-table>
			<div class="page">
				 <el-pagination
				      @size-change="handleSizeChange"
				      @current-change="handleCurrentChange"
				      :current-page="page"
				      :page-sizes="[10, 20, 30, 40]"
				      :page-size="limit"
				      layout="prev, pager, next, jumper,sizes,total"
				      :total="count">
				    </el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
	import {listCompanyOrderByCredit} from "@/api/supervise/supervise"
	export default {
		data() {
			return {
				autofocus: 0,
				tableData:[
					{num:1,name:"浙江世博新材股份有限公司",type:'印刷和媒介复制业',district:"城西",garden:'金田工业园区',Creditpoints:'100',},
					{num:2,name:"浙江世博新材股份有限公司",type:'印刷和媒介复制业',district:"城西",garden:'金田工业园区',Creditpoints:'98',},
					{num:3,name:"浙江世博新材股份有限公司",type:'印刷和媒介复制业',district:"城西",garden:'金田工业园区',Creditpoints:'96',},
					{num:4,name:"浙江世博新材股份有限公司",type:'印刷和媒介复制业',district:"城西",garden:'金田工业园区',Creditpoints:'95',},
					{num:5,name:"浙江世博新材股份有限公司",type:'印刷和媒介复制业',district:"城西",garden:'金田工业园区',Creditpoints:'94',},
					{num:6,name:"浙江世博新材股份有限公司",type:'印刷和媒介复制业',district:"城西",garden:'金田工业园区',Creditpoints:'20',}
				],
				page:1,
				limit:10,
				count:100,
				creditOrder:"desc",
				creditGroup:'',
				firmname:'',
				loading:false,
			}
		},
    props:["queryCreditGroup"],
    mounted() {
		  this.getScale(this.autofocus)
    },
    watch:{
      queryCreditGroup(val){
        if(val===1){this.creditGroup='优秀'}
        if(val===2){this.creditGroup='良好'}
        if(val===3){this.creditGroup='中等'}
        if(val===4){this.creditGroup='较差'}
        if(val===5){this.creditGroup='差'}
        this.page=1
        this.getScale()
      }
    },
    methods:{
      select(){
        this.page=1
        this.getScale()
      },
      activetype(val){
        this.creditOrder=val
        this.limit=10
        this.page=1
        this.getScale()
      },
	  handleSizeChange(val) {
        this.limit=val
        this.page=1
        this.getScale()
	  },
	  handleCurrentChange(val) {
        this.page=val
        this.getScale()
			},
      getScale(){
        this.loading=true
        let _this = this
		listCompanyOrderByCredit(_this.limit,_this.page,_this.creditOrder,_this.creditGroup,_this.firmname).then(res=> {
           console.log(res)
          if(res.data.data){
            _this.count=res.data.count
            _this.tableData=res.data.data
          }else{
            _this.count=0
            _this.tableData=[]
          }
          _this.loading=false
        })
      },
		}
	}
</script>

<style scoped="scoped" lang="less">
	.boxx{
		width: 100%;
		height: 100%;
		background-color: #FFFFFF;
		border-radius: 4px;
		padding: 4px;
		.top{
			width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
    .top/deep/.el-input{
      width: 50% !important;
    }
		.bom{
			margin-top: 20px;
			.page{
				display: flex;
				justify-content: flex-end;
				margin: 20px 0;
			}
		}
	}
</style>
