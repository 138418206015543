import fetch from "@/api/fetch"
// 信用+监测
export function creditGroup(){
	return fetch({
		url:'/apg/monitor/creditGroup',
		method:'get',
	})
}
export function listCompanyOrderByCredit(limit,page,creditOrder,grade,kw){
	const data={limit,page,creditOrder,grade,kw}
	return fetch({
		url:'/apg/longGang/company/listCompanyOrderByCredit',
		method:'post',
		data
	})
}
// radar
export function findEnvironment(){
	return fetch({
		url:'/apg/longGang/commonPortal/findEnvironment',
		method:'get',
	})
}
